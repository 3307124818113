
import { ref, defineComponent, computed, reactive } from 'vue'
import InputElement from '@/components/UI/Input.vue'
import Button from '@/components/UI/Button.vue'
import { useStore } from '@/store'
import { AuthActionTypes } from '@/store/auth'
import { useRoute } from 'vue-router'
import Loader from '@/components/UI/Loader.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'Home',
  components: {
    InputElement,
    Button,
    Loader
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const loader = computed(() => store.state.auth.loader)
    const resetPasswordError = computed(() => store.state.auth.resetPasswordError)
    const resetPasswordCred = ref({
      newPassword: '',
      confirmPassword: ''
    })
    const resetPasswordErrors = reactive({})
    const forgetPasswordEmail = ref({ email: '' })
    const onInputNewPassword = (value) => {
      resetPasswordCred.value['newPassword'] = value
    }
    const onInputRepeatNewPassword = (value) => {
      resetPasswordCred.value['confirmPassword'] = value
    }
    const validateInputs = () => {
      const regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/
      delete resetPasswordErrors['newPasswordError']
      delete resetPasswordErrors['confirmPasswordError']
      delete resetPasswordErrors['confirmPasswordSameError']
      delete resetPasswordErrors['invalidPasswordError']
      if (!resetPasswordCred.value.newPassword) {
        resetPasswordErrors['newPasswordError'] = 'required'
      }
      if (!regex.test(resetPasswordCred.value.newPassword)) {
        resetPasswordErrors['invalidPasswordError'] = true
      }
      if (!resetPasswordCred.value.confirmPassword) {
        resetPasswordErrors['confirmPasswordError'] = 'required'
      }
      if (resetPasswordCred.value.newPassword !== resetPasswordCred.value.confirmPassword) {
        resetPasswordErrors['confirmPasswordSameError'] = true
      }
      if (Object.getOwnPropertyNames(resetPasswordErrors).length === 0) {
        return true
      }
    }
    const resetPassword = () => {
      if (validateInputs()) {
        store.dispatch(AuthActionTypes.RESET_PASSWORD, {body: resetPasswordCred.value, email: route.params.email, activationKey: route.params.activationKey})
      }
    }
    const goToForgetPassword = () => {
      const query = Object.assign({}, route.query, { forgetPassword: true })
      router.push({name: 'login', query})
    }
    return {
      resetPasswordCred,
      onInputNewPassword,
      onInputRepeatNewPassword,
      forgetPasswordEmail,
      resetPassword,
      loader,
      resetPasswordErrors,
      resetPasswordError,
      goToForgetPassword
    }
  }
})
