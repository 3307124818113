
import { ref, defineComponent, onMounted, computed, reactive } from 'vue'
import InputElement from '@/components/UI/Input.vue'
import Button from '@/components/UI/Button.vue'
import { useStore } from '@/store'
import { MutationTypes } from '@/store/options/mutations'
import { AuthMutationTypes } from '@/store/auth/mutations'
import { AuthActionTypes } from '@/store/auth/actions'
import Loader from '@/components/UI/Loader.vue'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'Home',
  components: {
    InputElement,
    Button,
    Loader
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const loader = computed(() => store.state.auth.loader)
    const isError = computed(() => store.state.auth.isError)
    const route = useRoute()
    const showResetSuccess = computed(() => store.state.auth.showResetSuccess)
    const loginErrors = reactive({})
    const resetPasswordErrors = reactive({})
    const showForgetSection = ref(route.query.forgetPassword == 'true' ? true : false)
    const loginCred = ref({
      username: '',
      password: ''
    })
    const forgetPasswordEmail = ref({ email: '' })
    const onInputEmail = (value) => {
      loginCred.value['username'] = value
    }
    const onInputPassword = (value) => {
      loginCred.value['password'] = value
    }
    const onInputforgetPasswordEmail = (value) => {
      forgetPasswordEmail.value['email'] = value
    }
    const postLogInCred = async () => {
      await store.dispatch(AuthActionTypes.USER_LOGIN, loginCred.value)
      store.commit(MutationTypes.SET_LOGGEDIN, true)
    }
    const validateEmail = (value) => {
      // eslint-disable-next-line no-useless-escape
      const regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}')
      return regex.test(value) 
    }
    const validateInputs = () => {
      delete loginErrors['emailError']
      delete loginErrors['emailValidationError']
      delete loginErrors['passwordError']
      if (!loginCred.value.username) {
        loginErrors['emailError'] = 'required'
      }
      if (loginCred.value.username && !validateEmail(loginCred.value.username)) {
        loginErrors['emailValidationError'] = 'required'
      }
      if (!loginCred.value.password) {
        loginErrors['passwordError'] = 'required'
      }
      if (Object.getOwnPropertyNames(loginErrors).length === 0) {
        return true
      }
    }
    const validateResetPassword = () => {
      delete resetPasswordErrors['emailError']
      delete resetPasswordErrors['emailValidationError']
      if (!forgetPasswordEmail.value.email) {
        resetPasswordErrors['emailError'] = 'required'
      }
      if (forgetPasswordEmail.value.email && !validateEmail(forgetPasswordEmail.value.email)) {
        resetPasswordErrors['emailValidationError'] = 'required'
      }
      if (Object.getOwnPropertyNames(resetPasswordErrors).length === 0) {
        return true
      }
    }
    const logIn = () => {
      if (validateInputs()) {
        postLogInCred()
      }
    }
    const backToLogin = () => {
      showForgetSection.value = false
      const query = Object.assign({}, route.query, { forgetPassword: false })
      delete query.forgetPassword
      router.push({query})
      store.commit(AuthMutationTypes.SET_RESETSUCCESS, '')
    }
    const goToForgetSection = () => {
      showForgetSection.value = true
      const query = Object.assign({}, route.query, { forgetPassword: true })
      router.push({query})
    }
    const resetPassword = async () => {
      if (validateResetPassword()) {
        await store.dispatch(AuthActionTypes.FORGET_PASSWORD, { lang: localStorage.getItem('locale') || 'nl', body: forgetPasswordEmail.value })
      }
    }
    onMounted(() => {
      store.commit(MutationTypes.SET_LOGGEDIN, false)
      store.commit(AuthMutationTypes.SET_LOADER, false)
    })
    return {
      showForgetSection,
      loginCred,
      onInputEmail,
      onInputPassword,
      logIn,
      backToLogin,
      forgetPasswordEmail,
      onInputforgetPasswordEmail,
      resetPassword,
      showResetSuccess,
      loader,
      isError,
      validateEmail,
      loginErrors,
      resetPasswordErrors,
      goToForgetSection
    }
  }
})
